@import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.App {
  margin: 0 auto;
  height: 100vh;
}

.btn-primary {
  padding: 8px 16px;
  color: white;
  font-weight: 600;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.btn-primary:hover {
  background-color: rgb(29 78 216);
}
.btn-primary:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}

.error-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

/* For mobile phones: */
/* [class*="columns"] {
  flex-direction: "column";
} */

@media screen and (max-width: 640px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1536px) {
}
