.nav-container {
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: 15% auto 4%;
  padding: 24px 32px;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
  gap: 30px;
}
.nav-btn {
  color: rgb(249 250 251);
}
.nav-btn:hover {
  text-decoration: underline;
}
.nav-btn:active {
  text-decoration: underline;
  color: #f20a0e;
}
.mainMenu-container {
  display: flex;
}

.mobileMenu-container {
  display: none;
}

.mobileMenu-dropdown {
  padding: 2px 8px;
  /* background-color: rgb(30, 41, 59, 0.5); */
  /* background-color: rgba(255, 255, 255, 0.2); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100vh;
  transition: all 1s;
  /* display: absolute; */
}

/* padding: 2px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  display: absolute;
  z-index: 20;
  top: 45px; */
.mobileMenu-dropdown ul {
  padding: 6px;
  transition: all 1s;
}
.menu-transition {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.menu-button {
  display: inline-flex;
  background-color: transparent;
  border: none;
  color: rgb(249 250 251);
}

.menu-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.menu-icon {
  height: 24px;
  width: 24px;
  color: rgb(249 250 251);
}

.top-logo {
  height: 25px;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .mainMenu-container {
    display: none;
  }
  .mobileMenu-container {
    display: flex;
  }
  .nav-container {
    padding: 15px;
    grid-template-columns: 6% auto 15%;
  }
  .top-logo {
    max-height: 26px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .mainMenu-container {
    display: none;
  }
  .mobileMenu-container {
    display: flex;
  }
  .nav-container {
    padding: 15px;
    grid-template-columns: 6% auto 10%;
  }
  .top-logo {
    max-height: 26px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .mainMenu-container {
    display: none;
  }
  .mobileMenu-container {
    display: flex;
  }
  .nav-container {
    padding: 15px;
    grid-template-columns: 6% auto 8%;
  }
  .top-logo {
    max-height: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .nav-container {
    grid-template-columns: 20% auto 6%;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1536px) {
}
