.features-container {
  max-width: 1024px;
  margin: auto;
  padding: 120px 8px 50px 8px;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.features-container h1 {
  text-align: center;
  padding: 20px 0;
}

.features-grid {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0;
  gap: 20px;
  margin: 20px 0;
}

.features-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  position: relative;
}

.features-box-images {
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../images/waterCircle.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: -20;
}

/* .features-bg-img {
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: -20;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
} */

.features-item-image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-item-image img:nth-child(1) {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
}
.features-item-image img:nth-child(2) {
  height: 96%;
  position: absolute;
  /* top: 10px;
  right: 0;
  bottom: 0;
  left: 12px; */
  border-radius: 20px;
  z-index: -10;
  animation: showImageFromCenter 1.5s forwards;
}

.features-tempImg {
  height: 160px;
  width: 120px;
  position: absolute;
  bottom: 10%;
  right: 10%;
  border-radius: 10px;
  border: 2px solid rgb(120, 179, 237);
  box-shadow: 0 1px 3px 0 rgb(200 197 197 / 0.1),
    0 1px 2px -1px rgb(200 197 197 / 0.1);
  transform: rotate(0.03turn);
}

.features-box p {
  /* text-align: justify; */
  text-justify: inter-word;
  line-height: 24px;
  padding: 10px;
  animation: fadeToLeft 1s ease-in-out;
  position: relative;
}

.features-box-title {
  text-align: center;
  display: flex;
  align-items: center;
  color: rgb(249 250 251);
  padding: 0px 10px;
  animation: fadeToRight 1s ease-in-out;
}

.features-box-title h2 {
  padding-left: 10px;
}

.features-privacy-container {
  background-color: #1d93ed;
  position: absolute;
  bottom: -3%;
  padding: 15px;
  border-radius: 10px;
  left: 40%;
  right: 10%;
  box-shadow: 0 1px 3px 0 rgb(200 197 197 / 0.1),
    0 1px 2px -1px rgb(200 197 197 / 0.1);
  font-size: 12px;
}

/* media */
@media (max-width: 640px) {
  .features-box {
    width: 100%;
  }
  .features-grid:nth-child(even) {
    flex-direction: column-reverse;
  }
  .features-grid:nth-child(odd) {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .features-box {
    width: 100%;
  }
  .features-grid:nth-child(even) {
    flex-direction: column-reverse;
  }
  .features-grid:nth-child(odd) {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  /* .features-box {
    width: 100%;
  }
  .features-grid:nth-child(even) {
    flex-direction: column-reverse;
  }
  .features-grid:nth-child(odd) {
    flex-direction: column;
  } */
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}

/* keyFrames */
@keyframes fadeToRight {
  from {
    transform: translateX(-300px);
  }
}

@keyframes fadeToLeft {
  from {
    transform: translateX(300px);
  }
}

@keyframes showImageFromCenter {
  from {
    height: 0;
  }
  to {
    height: 96%;
  }
}
