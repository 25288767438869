.container {
  width: 100%;
  max-width: 1024px;
  margin: auto;
}

.mainContainer {
  padding: 10px;
  max-width: 1024px;
  margin: auto;
}

.containerCentered {
  justify-content: center;
  position: relative;
  direction: column;
  padding-top: 20px;
}

.centerItem {
  justify-content: center;
  display: flex;
  margin: auto;
}

.blue-btn {
  background-color: rgb(37 99 235);
  width: 50%;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

.blurBg {
  background-color: rgba(0, 0, 0, 0.5);
}

.title-h1 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 36px;
  margin-right: 8px;
  margin-left: 8px;
}

.text-sm {
  font-size: 10px;
}

.filter-none {
  filter: none;
}
.filter-grayscale {
  filter: grayscale(100%);
}
.borderTop {
  border-top: 1px solid rgb(107 114 128);
  width: 100%;
  position: relative;
}
.borderBottom {
  border-bottom: 1px solid rgb(107 114 128);
  width: 100%;
}
.tableFixed {
  table-layout: fixed;
}
.bold {
  font-weight: bold;
}
.bot50 {
  padding-bottom: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.textCentered {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}

.hide {
  display: none;
}

#animate-text {
  /* animation: all 5s ease-in-out; */
  animation-timing-function: linear;
  animation-duration: 10s;
}

/* For mobile phones: */
/* [class*="columns"] {
  flex-direction: "column";
} */

@media screen and (max-width: 640px) {
  .mainContainer {
    padding-top: 7rem;
  }
  .container {
    max-width: 640px;
  }
  /* .footer-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
  } */
}

@media screen and (max-width: 768px) {
  .mainContainer {
    padding-top: 7rem;
  }
  .container {
    max-width: 768px;
  }
  .copyright-container {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (max-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
