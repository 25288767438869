.home-container {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

.top-banner-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  overflow-x: hidden;
}

.top-Main-container {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 15;
  margin: 0;
  padding: 0;
}

.carousel-backgroundImage {
  width: 100%;
  height: 100%;
  position: fixed;
  object-fit: fill;
  z-index: -110;
  margin: 0;
  padding: 0;
}
.above-carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.above-carousel-container h1 {
  font-size: 70px;
  line-height: 1;
  padding: 0px 20px;
}

.above-carousel-container h2 {
  text-transform: uppercase;
  font-size: 20px;
}

.above-carousel-container img {
  height: auto;
  width: 100%;
}

.above-carousel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  z-index: 10;
}

/* thumbail */
.thumbnail {
  position: relative;
  left: 40%;
  display: flex;
  gap: 10px;
}
.thumbnail .item {
  width: 100px;
  height: 130px;
  filter: brightness(0.8);
}
.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}
/* arrows */
.arrows {
  position: relative;
  left: 30%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.arrows button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(38 38 38);
  border: none;
  color: rgb(249 250 251);
  font-family: monospace;
  font-weight: bold;
  transition: all 0.5s;
}
.arrows button:hover {
  background-color: green;
  color: #fff;
}
.aboutUs-container {
  padding: 15px;
  text-align: center;
}

.aboutUs-container h3 {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.bot-slideShow-container {
  position: relative;
  background-color: rgb(115, 115, 115);
  filter: brightness(0.8);
}

.bot-carousel-backgroundImage {
  width: 100%;
  height: 100%;
  background-size: contain;
  mix-blend-mode: overlay;
  position: absolute;
}

.bot-carousel-container {
  text-align: center;
  padding: 40px 0;
  max-width: 1024px;
  margin: auto;
}
.bot-carousel-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bot-carousel-container img {
  width: 20%;
  height: auto;
}

.bot-carousel-image {
  width: 160px;
}

.bot-carousel-arrows {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  right: -70%;
  left: -70%;
  bottom: 0;
}
.bot-carousel-arrows button {
  height: auto;
  width: 40px;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(38 38 38);
  border: none;
  color: rgb(249 250 251);
  font-family: monospace;
  font-weight: bold;
  transition: all 0.5s;
}
.bot-carousel-arrows button:hover {
  background-color: green;
  color: #fff;
}

.select-lang-container {
  padding: 2px 5px;
}

#transition-right {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeinRight 1s ease-in-out;
}

#transition-left {
  animation: fadeinLeft 1s ease-in-out;
}

#transition-thumbnailsIn {
  animation: fadeinRight 5s linear 1 forwards;
}

#transition-thumbnailsPrev {
  animation: showBackThumbnail 0.5s linear forwards;
}
#transition-thumbnailsNext {
  animation: hideThumbnail 0.5s linear backwards;
}

#transition-thumbnailsOut {
  animation: hideThumbnail 0.5s linear backwards;
}

#hide-thumbnail {
  display: none;
}

.transition-fade {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  animation: fadeIn 1s linear 1 forwards;
}

#animate-image {
  animation: showImage 0.5s linear 1 forwards;
}

.carousel-dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.carousel-dot-container span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 5px;
}

.no-spacing {
  margin: 0;
  padding: 0;
}

/* media */
@media (max-width: 640px) {
  .above-carousel-container {
    flex-direction: column;
  }
  .features-grid {
    grid-template-columns: auto;
  }
  .thumbnail .item {
    width: 40px;
    height: 60px;
  }
  .above-carousel-container h1 {
    font-size: 40px;
  }
  .bot-carousel-container img {
    width: 45%;
  }
  .aboutUs-container h3 {
    font-size: 15px;
  }
  .carousel-dot-container span {
    height: 7px;
    width: 7px;
  }
}

@media (max-width: 768px) {
  .above-carousel-container {
    flex-direction: column;
  }
  .features-grid {
    grid-template-columns: auto;
  }
  .thumbnail .item {
    width: 40px;
    height: 60px;
  }
  .above-carousel-container h1 {
    font-size: 40px;
  }
  .bot-carousel-container img {
    width: 45%;
  }
  .aboutUs-container h3 {
    font-size: 15px;
  }
  .carousel-dot-container span {
    height: 7px;
    width: 7px;
  }
}

@media (max-width: 1024px) {
  .above-carousel-container {
    flex-direction: column;
  }
  .features-grid {
    grid-template-columns: auto;
  }
  .thumbnail .item {
    width: 60px;
    height: 80px;
  }
  .above-carousel-container h1 {
    font-size: 40px;
  }
  .aboutUs-container h3 {
    font-size: 15px;
  }
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}

/* keyFrames */
@keyframes fadeIn {
  /* from {
    opacity: 0.5;
  } */
  to {
    /* opacity: 1; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes fadeinRight {
  from {
    transform: translateX(300px);
  }
}

@keyframes fadeinLeft {
  from {
    transform: translateX(-300px);
  }
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@keyframes fadeInSteps {
  from {
    transform: translateX(300px);
  }
}

@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}

@keyframes hideThumbnail {
  /* from {
    display: none;
  } */
  to {
    width: 0%;
    height: 0%;
    opacity: 0;
    display: none;
  }
}

@keyframes showBackThumbnail {
  from {
    width: 1%;
    height: 10%;
  }
  to {
    height: 100%;
  }
}
