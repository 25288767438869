* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

body {
  background-color: rgb(38 38 38);
  color: rgb(249 250 251);
  font-size: 16px;
  font-family: "Poppins", "Roboto", Verdana, Arial, Helvetica, "Times New Roman",
    Times, serif, sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  font-size: 30px;
  font-family: "Italiana", "Roboto", Verdana, Arial, Helvetica,
    "Times New Roman", Times, serif, sans-serif;
}

h2 {
  font-size: 20px;
  margin: 0;
  font-family: Verdana, Arial, Helvetica, "Times New Roman", Times, serif,
    sans-serif;
}

h3 {
  font-size: 18px;
  font-family: "Italiana", "Roboto", Verdana, Arial, Helvetica,
    "Times New Roman", Times, serif, sans-serif;
}

h4 {
  font-size: 16px;
  font-family: "Italiana", "Roboto", Verdana, Arial, Helvetica,
    "Times New Roman", Times, serif, sans-serif;
}

h5 {
  font-size: 14px;
  margin: 0;
  font-family: "Italiana", "Roboto", Verdana, Arial, Helvetica,
    "Times New Roman", Times, serif, sans-serif;
}

h6 {
  font-size: 12px;
  margin: 0;
  font-family: "Italiana", "Roboto", Verdana, Arial, Helvetica,
    "Times New Roman", Times, serif, sans-serif;
}
footer {
  text-align: center;
  position: relative;
  border-top: 1px solid rgb(107 114 128);
  background-color: rgb(38 38 38);
  padding-top: 10px;
}
span {
  font-size: 13px;
  font-family: "Montserrat", Arial, sans-serif;
}
p {
  margin: 0;
  /* font-family: "Roboto", Arial, sans-serif; */
  font-family: Verdana, Tahoma, Arial, Helvetica, sans-serif sans-serif;
  /* font-weight: 400;
  font-style: normal; */
}

section {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1024px;
  background-color: aqua;
  margin: auto;
  padding-top: 120px;
  text-align: center;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
