.pro-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 10px 0px;
  position: relative;
  background-image: url("../assets/curveWave.png");
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100%;
}

.pro-title {
  text-align: center;
  padding: 10px 0;
}

.row {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 30px 0px;
}

.price {
  list-style-type: none;
  border: 1px solid #fefefe;
  border-radius: 20px;
  margin: 10px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #111;
  border-radius: 20px;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.price .header {
  background-color: #111;
  border-radius: 20px 20px 0px 0px;
  color: white;
  font-size: 25px;
  text-align: center;
  list-style: none;
}

.price li {
  padding: 12px;
  text-align: left;
}

.price .grey {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  font-size: 20px;
  border-radius: 0px 0px 20px 20px;
  height: 100px;
}

.price .amount {
  font-size: 30px;
  text-align: center;
  list-style: none;
  font-weight: bold;
}

.price .amountYearly {
  font-size: 40px;
  text-align: center;
  list-style: none;
  font-weight: bold;
}
.price li {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 5px 12px;
}

.buttonPrice {
  background-color: #04aa6d;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  width: 80%;
}

.text-center {
  text-align: center;
}

#proBenefits {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#proBenefits td,
#proBenefits th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  color: rgb(249 250 251);
}
#proBenefits tr:nth-child(even) {
  background-color: rgb(38 38 38);
}

#proBenefits tr:hover {
  background-color: rgb(82 82 82);
}

#proBenefits th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: rgb(249 250 251);
}

@media screen and (max-width: 640px) {
  .row {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .row {
    grid-template-columns: 1fr;
    padding: 0px 8px;
  }
}

@media screen and (max-width: 1024px) {
  .row {
    grid-template-columns: 1fr;
    padding: 0px 8px;
  }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1536px) {
}
