.copyright-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 15px;
  align-items: center;
  position: relative;
  border-top: 1px solid rgb(107 114 128);
  place-self: center;
}

.footer-content {
  margin: auto;
  position: relative;
  display: grid;
  gap: 2rem;
  padding: 20px 5px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.footer-content h2 {
  padding-bottom: 8px;
}

.terms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-container {
  /* display: inline-flex; */
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.download-container img {
  width: 30%;
}
.download-container img:hover {
  background-color: rgb(34, 95, 239);
  border-radius: 10px;
  border: 2px solid rgb(52, 98, 206);
}

.download-containerBot {
  padding: 10px;
  position: "relative";
  max-width: 1024px;
  margin: auto;
}

.social-container {
  display: flex;
  flex-direction: column;
}
.linkText {
  text-decoration-line: none;
  font-size: 13px;
  white-space: nowrap;
  color: rgb(249 250 251);
}
.linkText:hover {
  color: rgb(69, 136, 230);
}
.linkText:focus {
  text-decoration-line: underline;
  color: rgb(69, 136, 230);
}

.link-social-icon {
  padding: 0 5px;
  color: rgb(249 250 251);
}
.link-social-icon:hover {
  color: rgb(69, 136, 230);
}

.termsPrivacy-container {
  padding: 10px;
  max-width: 1024px;
  margin: auto;
  padding-top: 120px;
}
.termsPrivacy-container h1 {
  padding: 15px 0px;
}
.termsPrivacy-container h4 {
  padding: 15px 0px;
  font-size: 18px;
}

.termsPrivacy-container p {
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
}

.termsPrivacy-container a {
  padding-left: 3px;
  color: rgb(38, 51, 235);
}

.termsPrivacy-container a:hover {
  color: rgb(10, 237, 135);
}

.contact-form-container {
  padding: 100px 8px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}
.contact-form-container h1 {
  padding: 20px;
  font-size: 40px;
}
.contact-form-box {
  border-radius: 5px;
  background-color: rgb(29, 29, 29);
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  margin-top: 30px;
}

.contact-form-box button {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form-box button:hover {
  background-color: #45a049;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .copyright-container {
    width: 100%;
  }
  .download-container img {
    width: 40%;
  }
  .footer-content {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .copyright-container {
    width: 100%;
  }
  .download-container img {
    width: 35%;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}
